import { features } from "node:process";
import React from "react";
import { Box } from "rebass/styled-components";

import { FeatureToggle, ListContent } from "../../models/app-data-model";
import ShLink from "../sh-link/sh-link";
import "./sh-list.scss";

export type ShListProps = {
  content: ListContent;
  featureToggles: FeatureToggle[];
};

const ShList: React.FC<ShListProps> = ({ content, featureToggles }: ShListProps) => {
  const listTag = content.list_type === "ordered" ? "ol" : "ul";
  const listClass = content.hide_list_markers ? "no-list-style" : "";
  const testId = content.hide_list_markers ? `${listTag}-${listClass}` : listTag;
  const listItems: JSX.Element[] = [];

  for (let index = 0; content.list_items.length > 1 && index < content.list_items?.length; index++) {
    const listItem = content.list_items[index];

    if (listItem.reference?.length) {
      listItems.push(
        <li className="list-item large-text" key={`li-${index}`}>
          <ShLink
            className="list-item-link"
            reference={listItem.reference}
            title={listItem.list_item_text}
            key={`li-link-${index}`}
            featureToggles={featureToggles}
          />
        </li>
      );
    } else {
      listItems.push(
        <li className="list-item large-text" key={`li-${index}`}>
          {content.list_items[index].list_item_text}
        </li>
      );
    }
  }

  return (
    <Box as={listTag} className={`sh-list large-text ${listClass}`} data-testid={testId}>
      {listItems}
    </Box>
  );
};

export default ShList;
