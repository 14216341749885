import { ReferenceLinkContent } from "../../models/app-data-model";

export type SimpleReference = {
  href: string;
  type: "sh_pages" | "sh_location_page" | "sh_mobile_app_pages" | "sh_external_link";
};

export type RegionalLinkReference = {
  title: string;
  default_link: ReferenceLinkContent[];
  west_region_link: ReferenceLinkContent[];
  southeast_region_link: ReferenceLinkContent[];
  southwest_region_link: ReferenceLinkContent[];
  type: "sh_regional_link_component";
};

/**
 * This hook is meant to get the URL from a ReferenceLinkContent array
 * It is meant to handle internal and external links only at this time
 *
 * @param reference (as a ReferenceLinkContent array)
 * @returns a URL (internal or external) as a string or undefined if no URL is found
 */
function useReferenceUrl(reference?: ReferenceLinkContent[]): SimpleReference | RegionalLinkReference | undefined {
  let contentType =
    reference?.find((el) => el.internal?.type)?.internal?.type || reference?.find((el) => el?._content_type_uid)?._content_type_uid;
  const externalLink = reference?.find((el) => el?.link?.length)?.link?.find((el) => el?.href)?.href;

  const contentTypeId = reference?.find((el) => el._content_type_uid);

  // when we get referencelinkcontent from contentstack at runtime through the api instead of graphql
  // the types lack the `sh_` prefix
  if (contentType === "pages" || contentType === "mobile_app_pages") {
    contentType = "sh_pages";
  } else if (contentType === "location_page") {
    contentType = "sh_location_page"
  } else if (contentType === "external_link") {
    contentType = "sh_external_link";
  } else if (contentType === "regional_link_component") {
    contentType = "sh_regional_link_component";
  }

  // Link can be an internal page, an external link, or regional links
  if (contentType === "sh_pages" || contentType === "sh_location_page" || contentType === "sh_mobile_app_pages") {
    return { href: `${reference?.find((el) => el?.url)?.url}`, type: contentType };
  } else if (contentType === "sh_external_link" && externalLink) {
    return { href: externalLink, type: contentType };
  } else if (contentTypeId?._content_type_uid === "regional_link_component") {
    return {
      title: reference?.[0].title,
      default_link: reference?.[0].default_link,
      west_region_link: reference?.[0].west_region_link,
      southeast_region_link: reference?.[0].southeast_region_link,
      southwest_region_link: reference?.[0].southwest_region_link,
      type: "sh_regional_link_component"
    } as RegionalLinkReference;
  } else if (contentType === "sh_regional_link_component") {
    return {
      ...reference?.find((el) => el.internal?.type === contentType),
      type: contentType
    } as RegionalLinkReference;
  } else {
    return undefined;
  }
}

export default useReferenceUrl;
