/* eslint-disable default-case */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import { DeviceType } from '../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../shared/hooks/use-breakpoint/useBreakpoint';
import useFeatureToggle from '../../shared/hooks/use-feature-toggle/useFeatureToggle';
import usePagination from '../../shared/hooks/use-pagination/usePagination';
import { FeatureToggle, GlobalSearchNoResultsContent, IconPack } from '../../shared/models/app-data-model';
import { KendraResponseCategory, Location, Page, Provider } from '../../shared/services/kendra-api/kendra-api-service';
import LocationCardDesktop from '../locations/location-card-desktop/location-card-desktop';
import LocationCardMobile from '../locations/location-card-mobile/location-card-mobile';
import NoResults from '../no-results/no-results';
import PageContent from '../pages/page-content/page-content';
import Paginator from '../paginator/paginator';
import { ProviderItem } from '../providers-list/providers-list';
import SearchTotalResults from '../search-total-results/search-total-results';
import './all-results.scss';
import useFilteredQueryParams from '../../shared/hooks/filter-params/useFilteredQueryParams';
import { navigate } from 'gatsby';

export type AllResultsProps = {
  dynamicResults?: boolean;
  results: Array<Provider | Location | Page>;
  totalResults?: number;
  searchTerm?: string;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  searchSuggestions?: string[];
  noResultsFoundContent?: GlobalSearchNoResultsContent;
  activePage?: number;
  isKyruusData?: boolean;
  kyruusOriginCity?: string;
};

const AllResults: React.FC<AllResultsProps> = (props: AllResultsProps) => {
  const {dynamicResults, results, featureToggles, iconPack, totalResults, searchSuggestions, noResultsFoundContent, activePage, isKyruusData, kyruusOriginCity } = props;
  const setActivePage = activePage || 1;
  const deviceType = useBreakpoint();
  const showLocations = useFeatureToggle('search-locations', featureToggles);
  const showArticles = useFeatureToggle('search-articles', featureToggles);
  const showProviders = useFeatureToggle('search-providers', featureToggles);
  const [currentPageData, paginationDetails, setCurrentPage] = usePagination(results);
  const resultsTotal = totalResults ? totalResults : results.length;

  const [paramCurrentPage, setParamCurrentPage] = useState(setActivePage);
  const [totalPages] = useState(Math.ceil(resultsTotal / 10));
  const paramPaginationDetails = { currentPage: paramCurrentPage, totalPages: totalPages };
  const isDynamic = dynamicResults || false;

  // if this isnt present the gatsby server side build fails: https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
  let queryParams = new URLSearchParams();
  const isBrowser = typeof window !== "undefined";
  /* istanbul ignore else */
  if (isBrowser) {
    queryParams = new URLSearchParams(window?.location.search);
  }
  const paramsFilter = ["query", "tab", "per_page", "origin", "distance", "filter", "orFilter", "notFilter"];
  const retainParams = useFilteredQueryParams(queryParams, paramsFilter, {});

  const onPageChange = useCallback(
    (currentPage: number): void => {
      if (isDynamic) {
        setParamCurrentPage(currentPage);
        navigate(`/search/?${retainParams}&page=${encodeURI(currentPage.toString())}`);
      } else {
        setCurrentPage(currentPage);
      }
      window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    [setParamCurrentPage, setCurrentPage, isDynamic]
  );

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [currentPageData]);

  const allResults = currentPageData?.map((result, index) => {
    const resultsCardId = `results-card-${index}`;
    switch (result._category) {
      case KendraResponseCategory.Providers:
        return (
          showProviders && (
            <Box key={index} className="all-results-providers-list">
              <ProviderItem iconPack={iconPack} provider={result as Provider} elementId={resultsCardId} />
            </Box>
          )
        );
      case KendraResponseCategory.Locations:
        return (
          showLocations && (
            <Box key={index} className="all-results-locations-list">
              {deviceType === DeviceType.Desktop ? (
                <LocationCardDesktop 
                  iconPack={iconPack} 
                  location={result as Location} 
                  elementId={resultsCardId} 
                  isKyruusData={isKyruusData} 
                  kyruusOriginCity={kyruusOriginCity} 
                />
              ) : (
                <LocationCardMobile 
                  iconPack={iconPack} 
                  location={result as Location} 
                  elementId={resultsCardId}
                  isKyruusData={isKyruusData}
                  kyruusOriginCity={kyruusOriginCity}
                />
              )}
            </Box>
          )
        );
      case KendraResponseCategory.Pages:
        return (
          <Box key={index} className="all-results-pages-list">
            <PageContent page={result as Page} elementId={resultsCardId} isKyruusData={isKyruusData} />
          </Box>
        );
      case KendraResponseCategory.Articles:
        return (
          showArticles && (
            <Box key={index} className="all-results-articles-list">
              <PageContent page={result as Page} elementId={resultsCardId} isKyruusData={isKyruusData} />
            </Box>
          )
        );
    }
  });

  return (
    <>
      {results?.length > 0 ? (
        <Flex className="results-container search-all-tab-result-container" flexDirection="column" marginTop={[2]} flex={1} px={2}>
          <SearchTotalResults count={resultsTotal} searchTerm={props.searchTerm} dynamicSearch={true} />
          {allResults}
          <Paginator paginationDetails={isDynamic ? paramPaginationDetails : paginationDetails} onPageChange={onPageChange} iconPack={iconPack} dynamicSearch={true} />
        </Flex>
      ) : (
        <NoResults
          content={noResultsFoundContent}
          iconPack={iconPack}
          searchSuggestions={searchSuggestions}
          featureToggles={featureToggles}
          searchTerm={props.searchTerm}
        />
      )}
    </>
  );
};

export default AllResults;
